import { Button, Col, Drawer, Input, Radio, Row, Select, Space, Switch, Tooltip } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

// Default shape of a single filter block
const defaultFilter = {
    dateExcluded: false,
    dateField: 'BEGIN',
    startDate: null,
    endDate: null,
    servicesExcluded: false,
    recommendServicesExcluded: false,
    partsExcluded: false,
    serviceIds: [],
    recommendServiceIds: [],
    partIds: [],
    employeeId: null,
    startMileage: 0,
    endMileage: 1000000,
    concatType: 'AND'
};

const STORAGE_KEY = 'drawerCompoundFiltersData';
const MAX_FILTERS = 5;

const CompoundFilterDrawer = ({ drawerOpen, onClose, onOk }) => {
    const intl = useIntl();

    // Current, unsaved filters
    const [filters, setFilters] = useState([]);

    // Dropdown data
    const [selectOptions, setSelectOptions] = useState({
        labors: [],
        productId: [],
        appurtenanciesResponsibleId: []
    });

    // Loads filters from localStorage into state
    const revertFiltersFromStorage = useCallback(() => {
        const savedData = localStorage.getItem(STORAGE_KEY);
        if (savedData) {
            try {
                const parsed = JSON.parse(savedData);
                if (parsed?.filters) {
                    setFilters(parsed.filters);

                    return;
                }
            } catch (err) {
                console.warn('Failed to parse saved filters:', err);
            }
        }
        // If nothing saved, or parse error => revert to empty
        setFilters([]);
    }, []);

    // 1) On mount (or whenever the drawer opens), load from local storage
    useEffect(() => {
        if (drawerOpen) {
            revertFiltersFromStorage();
        }
    }, [drawerOpen, revertFiltersFromStorage]);

    // 2) On “Cancel” or “X” close => revert, then close
    const handleCancel = () => {
        revertFiltersFromStorage();
        onClose();
    };

    // 3) Debounced search for labors
    const fetchLabors = useCallback(async input => {
        try {
            const { labors: labs = [] } = await fetchAPI(
                'GET',
                'labors',
                { search: input?.replace(/-/g, ''), page: 1, pageSize: 25 },
                null,
                { handleErrorInternally: true }
            );
            setSelectOptions(prev => ({
                ...prev,
                labors: labs.map(({ id, name }) => ({ id, name }))
            }));
        } catch (err) {
            console.error('fetchLabors error:', err);
        }
    }, []);

    useEffect(() => {
        fetchLabors();
    }, []);

    const handleSearchLabors = useMemo(() => debounce(fetchLabors, 500), [fetchLabors]);

    // 4) Debounced search for products
    const fetchProducts = useCallback(async input => {
        try {
            const resp = await fetchAPI(
                'GET',
                '/store_products',
                { search: input || '', page: 1, pageSize: 25 },
                null,
                { handleErrorInternally: true }
            );
            const products = (resp?.list || []).map(({ id, code, name, brandName }) => ({
                id,
                name: `${code} ${name} ${brandName || ''}`.trim()
            }));
            setSelectOptions(prev => ({ ...prev, productId: products }));
        } catch (err) {
            console.error('fetchProducts error:', err);
        }
    }, []);

    const handleSearchProducts = useMemo(() => debounce(fetchProducts, 500), [fetchProducts]);

    // 5) Fetch base data on mount (mechanics & initial products)
    useEffect(() => {
        const fetchBaseData = async () => {
            try {
                // Mechanics
                const employeesResp = await fetchAPI('GET', '/employees', null, null, { handleErrorInternally: true });
                const employees = employeesResp.map(({ id, name, surname }) => ({
                    id,
                    name: `${surname ?? ''} ${name ?? ''}`.trim()
                }));

                // Products
                const productsResp = await fetchAPI('GET', '/store_products', { page: 1, pageSize: 25 }, null, {
                    handleErrorInternally: true
                });
                const products = (productsResp?.list || []).map(({ id, code, name, brandName }) => ({
                    id,
                    name: `${code} ${name} ${brandName || ''}`.trim()
                }));

                setSelectOptions(prev => ({
                    ...prev,
                    appurtenanciesResponsibleId: employees,
                    productId: products
                }));
            } catch (err) {
                console.error('Failed fetching mechanics/parts:', err);
            }
        };

        fetchBaseData();
    }, []);

    // Helper: add new filter
    const handleAddFilter = () => {
        if (filters.length < MAX_FILTERS) {
            setFilters([...filters, { ...defaultFilter }]);
        }
    };

    // Helper: remove filter
    const handleRemoveFilter = index => {
        setFilters(prev => prev.filter((_, i) => i !== index));
    };

    // Helper: update single field
    const handleFilterChange = (index, field, value) => {
        setFilters(prev => {
            const newFilters = [...prev];
            newFilters[index] = { ...newFilters[index], [field]: value };

            return newFilters;
        });
    };

    // Save filters only on confirm
    const handleApplyFilters = () => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify({ filters }));
        onOk?.();
        onClose();
    };

    console.log(selectOptions.labors);

    return (
        <Drawer
            extra={
                <Space>
                    {/* Pressing Cancel => revert & close */}
                    <Button onClick={handleCancel}>
                        <FormattedMessage id='stock_table.button.cancel' />
                    </Button>
                    <Button onClick={handleApplyFilters} type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Space>
            }
            onClose={handleCancel} // If user clicks X => revert & close
            open={drawerOpen}
            placement='right'
            title={
                <Tooltip title={<FormattedMessage id='compound_filter.tooltip' />}>
                    <FormattedMessage id='filters' />
                </Tooltip>
            }
            width={860}
        >
            {filters.map((filter, index) => (
                <div
                    key={index}
                    style={{
                        padding: 16,
                        marginBottom: 16,
                        border: '1px solid #ddd',
                        borderRadius: 4,
                        position: 'relative'
                    }}
                >
                    <p style={{ position: 'absolute' }}>
                        <FormattedMessage id='employee_salary_rules.condition' /> {index + 1}
                    </p>

                    {/* Operator (AND/OR) only if not first filter */}
                    {index > 0 && (
                        <Row className={Styles.blockRow}>
                            <Col className={Styles.radio} span={24}>
                                <Radio.Group
                                    onChange={e => handleFilterChange(index, 'concatType', e.target.value)}
                                    value={filter.concatType}
                                >
                                    <Radio value='AND'>
                                        <FormattedMessage id='and' />
                                    </Radio>
                                    <Radio value='OR'>
                                        <FormattedMessage id='or' />
                                    </Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    )}

                    {/* 1) visited switch + date range + date type */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2}>
                            <Switch
                                checked={!filter.dateExcluded}
                                onChange={checked => handleFilterChange(index, 'dateExcluded', !checked)}
                            />
                        </Col>
                        <Col span={6}>
                            <span style={{ marginLeft: 8 }}>
                                {!filter.dateExcluded ? (
                                    <FormattedMessage id='universal_filters_form.visited' />
                                ) : (
                                    <FormattedMessage id='universal_filters_form.not_visited' />
                                )}
                            </span>
                        </Col>
                        <Col span={16}>
                            <DateRangePicker
                                allowClear
                                dateRange={filter.startDate ? [dayjs(filter.startDate), dayjs(filters.endDate)] : []}
                                getPopupContainer={trigger => trigger.parentNode}
                                onDateChange={dateRange => {
                                    handleFilterChange(index, 'startDate', dateRange[0].format('YYYY-MM-DD'));
                                    handleFilterChange(index, 'endDate', dateRange[1].format('YYYY-MM-DD'));
                                }}
                            />
                            <div className={Styles.radio} style={{ marginTop: 8 }}>
                                <Radio.Group
                                    onChange={e => handleFilterChange(index, 'dateField', e.target.value)}
                                    value={filter.dateField}
                                >
                                    <Radio value='BEGIN'>
                                        <FormattedMessage id='of_record' />
                                    </Radio>
                                    <Radio value='SUCCESS'>
                                        <FormattedMessage id='of_success' />
                                    </Radio>
                                    <Radio value='DELIVERY'>
                                        <FormattedMessage id='of_delivery' />
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>

                    {/* 2) Completed Services (labors) */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2}>
                            <Switch
                                checked={!filter.servicesExcluded}
                                onChange={checked => handleFilterChange(index, 'servicesExcluded', !checked)}
                            />
                        </Col>
                        <Col span={6}>
                            <span style={{ marginLeft: 8 }}>
                                {!filter.servicesExcluded ? (
                                    <FormattedMessage id='completed_services' />
                                ) : (
                                    <FormattedMessage id='uncompleted_services' />
                                )}
                            </span>
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                fieldNames={{ label: 'name', value: 'id' }}
                                filterOption={false}
                                mode='multiple'
                                onChange={vals => handleFilterChange(index, 'serviceIds', vals)}
                                onSearch={handleSearchLabors}
                                options={selectOptions.labors}
                                placeholder={intl.formatMessage({ id: 'labors' })}
                                showSearch
                                style={{ width: '100%' }}
                                value={filter.serviceIds}
                            />
                        </Col>
                    </Row>

                    {/* 3) Recommended Services (labors) */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2}>
                            <Switch
                                checked={!filter.recommendServicesExcluded}
                                onChange={checked => handleFilterChange(index, 'recommendServicesExcluded', !checked)}
                            />
                        </Col>
                        <Col span={6}>
                            <span style={{ marginLeft: 8 }}>
                                {!filter.recommendServicesExcluded ? (
                                    <FormattedMessage id='recomended_services' />
                                ) : (
                                    <FormattedMessage id='unrecomended_services' />
                                )}
                            </span>
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                fieldNames={{ label: 'name', value: 'id' }}
                                filterOption={false}
                                mode='multiple'
                                onChange={vals => handleFilterChange(index, 'recommendServiceIds', vals)}
                                onSearch={handleSearchLabors}
                                options={selectOptions.labors}
                                placeholder={intl.formatMessage({ id: 'labors' })}
                                showSearch
                                style={{ width: '100%' }}
                                value={filter.recommendServiceIds}
                            />
                        </Col>
                    </Row>

                    {/* 4) Parts Installed (productId) */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2}>
                            <Switch
                                checked={!filter.partsExcluded}
                                onChange={checked => handleFilterChange(index, 'partsExcluded', !checked)}
                            />
                        </Col>
                        <Col span={6}>
                            <span style={{ marginLeft: 8 }}>
                                {!filter.partsExcluded ? (
                                    <FormattedMessage id='instaled_parts' />
                                ) : (
                                    <FormattedMessage id='not_instaled_parts' />
                                )}
                            </span>
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                fieldNames={{ label: 'name', value: 'id' }}
                                mode='multiple'
                                onChange={vals => handleFilterChange(index, 'partIds', vals)}
                                onSearch={handleSearchProducts}
                                options={selectOptions.productId}
                                placeholder={intl.formatMessage({ id: 'instaled_parts' })}
                                showSearch
                                style={{ width: '100%' }}
                                value={filter.partIds}
                            />
                        </Col>
                    </Row>

                    {/* 5) Mechanic select (from appurtenanciesResponsibleId) */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2} />
                        <Col span={6}>
                            <FormattedMessage id='mechanic' />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                fieldNames={{ label: 'name', value: 'id' }}
                                onChange={val => handleFilterChange(index, 'employeeId', val)}
                                options={selectOptions.appurtenanciesResponsibleId}
                                placeholder={intl.formatMessage({ id: 'mechanic' })}
                                showSearch
                                style={{ width: '100%' }}
                                value={filter.employeeId}
                            />
                        </Col>
                    </Row>

                    {/* 6) Mileage range */}
                    <Row className={Styles.blockRow} gutter={8}>
                        <Col span={2} />
                        <Col span={6}>
                            <FormattedMessage id='orders.odometer' />
                        </Col>
                        <Col span={8}>
                            <Input
                                onChange={e => handleFilterChange(index, 'startMileage', Number(e.target.value))}
                                placeholder={intl.formatMessage({ id: 'universal_filters_form.mileage_from' })}
                                type='number'
                                value={filter.startMileage}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                onChange={e => handleFilterChange(index, 'endMileage', Number(e.target.value))}
                                placeholder={intl.formatMessage({ id: 'universal_filters_form.mileage_to' })}
                                type='number'
                                value={filter.endMileage}
                            />
                        </Col>
                    </Row>

                    {/* Remove filter button */}
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button danger onClick={() => handleRemoveFilter(index)} type='text'>
                                <FormattedMessage id='delete_condition' />
                            </Button>
                        </Col>
                    </Row>
                </div>
            ))}

            {/* Add filter button */}
            <Button
                disabled={filters.length >= MAX_FILTERS}
                onClick={handleAddFilter}
                style={{ width: '100%', marginBottom: 16 }}
                type='dashed'
            >
                <FormattedMessage id='add_condition' />
            </Button>
        </Drawer>
    );
};

export default CompoundFilterDrawer;
